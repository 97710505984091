import React, { useState , useCallback, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Container, ContainerItem, StickyTitle } from 'components/UIkit';


const Body = (props) => {
  return(
    <Typography variant='body1' sx={{p:1, ...props.sx}} {...props} >
      {props.children}
    </Typography>
  )
}

const Small = (props) => {

  return(
    <span style={{
      fontSize: ".75rem"
    }}>
      {props.children}
    </span>
  )
}

const Main = props => {

  return (
    <Container>
      <ContainerItem>

        <Typography variant="h4" component={"h1"} textAlign={"center"} sx={{mt:2, mb:2}} >
          開発レポート
        </Typography>
        <Typography variant="subtitle1" textAlign={"center"} sx={{mt:2, mb:8}} >
          最終更新日 2022/01/04
        </Typography>

        <StickyTitle>はじめに</StickyTitle>

        <Body>

          楽単アルプスのご利用ありがとうございます！
          <br />
          <br />
          「開発レポート」は、アプリの開発中思ったことをツラツラ書いてく場所にしようかと思います。
          <br />
          Web系の専門用語的なのが出てくるのは、ご了承ください。
          <br />
          <br />
          バグや開発中の機能は、「お知らせ」ページを作ってまとめようと考えています。

        </Body>


        <StickyTitle>開発のきっかけ</StickyTitle>
        <Body>

          大学生になってほやほやの去年(21年)の春、「信大ガイド」を見て衝撃を受けた
          <br />
          <br />
          「大学生ってこんなん作れるんか〜ww、おいらもこんなサイト作りたいでごわす！！」
          <br />
          「授業レビューサイトとか作りたい！！」
          <br />
          <br />
          そのころは、ウェブに関してはガッチガチの素人でだったので、HTMLから勉強を始めました
          <br />
          <br />
          結果、勉強始めて半年ちょっとで割といいサイト作れたんじゃないかな。。と
          <br />
          <br />
          
          <Small>
           (「信大ガイド」とかはプログラミング言語知らなくても作れる、と勉強始めて２〜３ヶ月目にして知った...)
          </Small> 
          
        </Body>

        <StickyTitle>開発言語</StickyTitle>
        <Body>
          使った言語書いてくよん
          <br />
          <ul>
            <li>HTML</li>
            <li>CSS</li>
            <li>React.JS (create-react-app)</li>
            <li>バニラPHP</li>
            <li>FireBase</li>
          </ul>

          「かっこいいUI作りたい！！」
          <br />
          <br />
          「Material UI かっこいい！！」
          <br />
          <br />
          「React 使おう ！！」
          <br />
          <br />

          でReact選びました。
          <br />
          <br />
          PHPがバニラなのは、激安サーバーはコマンドシェルが使えない + Laravelとか勉強したくないから
          <br />
          <br />
          最初はバックエンドをぜーんぶFirebaseに頼もうとしたけど、料金的に絶対無理。一部だけ導入しましたわ。

         
          
        </Body>

        <StickyTitle>いざ開発！！</StickyTitle>

        <Body>
          (とりあえず、PCがまともに動いてくれないので今日はこの辺で...)
        </Body>


      </ContainerItem>
    </Container>
  )
}

export default Main;