import React from "react"
import Layout from "layout"
import { Main } from "components/pages/Report"
import Seo from "seo";

export default function History (props) {


    return (
        <Layout>
          <Seo title={"開発レポート"} />
          <Main />
        </Layout>
    )

}